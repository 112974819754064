<template>
  <div>
    <!-- sync contract number card -->
    <collapse-card
      :model-value="true"
      :show-collapse-button="false"
      disable-collapse
      class="w-full"
    >
      <template #title>
        <span class="mr-2 text-sm font-medium">
          Contract Number Sync
        </span>
      </template>
      <template #cardContent>
        <div v-if="isContractSyncInProgress || isViewRefreshing" class="py-8 px-4 w-full flex justify-center">
          <base-svg class="h-4 w-4 mr-1 text-primary-red inline-block" src="icons/circleSpinner.svg" tag="span"/>
          Please wait ...
        </div>
        <div v-else class="py-2 px-4">
          <base-input
            v-model="contractSyncData.contractNumber"
            type="text"
            container-class="flex mb-4"
            label-class="label-w-46"
            label="Contract Number"
          />
          <div class="flex justify-between">
            <div>
              <base-button
                variant="btn-primary"
                :disabled="!isSynctBtnActive"
                class="mr-4"
                text="Sync"
                @click="handleSync()"
              />
              <base-button
                variant="btn-link"
                text="Reset"
                @click="handleReset()"
              />
            </div>
            <div>
              <base-button
                variant="btn-link"
                class="mr-4"
                text="Refresh Views"
                @click="handleRefreshViews()"
              />
              <!-- <base-button
                variant="btn-link"
                text="Status"
                @click="handleSyncFormStatus()"
              /> -->
            </div>
          </div>
        </div>
      </template>
    </collapse-card>

    <div v-if="contractDetailsData.length">
      <div class="mt-16 mb-4 text-primary-gray text-base font-semibold">Following contracts have been synced</div>

      <collapse-card
        v-for="(contractDetails, cIndex) in contractDetailsData"
        :key="'contractDetails-'+cIndex"
        :model-value="true"
        :show-collapse-button="false"
        disable-collapse
        class="w-full"
      >
        <template #title>
          <span class="mr-2 text-sm font-normal">
            Contract Number - <span class="font-semibold">{{contractDetails.contractNumber}}</span>
          </span>
        </template>
        <template #cardContent>
          <div class="py-2 px-4">
            <div class="grid grid-cols-2 gap-x-6 gap-y-4">
              <base-input
                :model-value="contractDetails.licensee"
                type="text"
                container-class="flex items-center"
                label-class="label-w-46"
                label="Licensee"
                disabled
              />
              <base-input
                :model-value="contractDetails.licenseeId"
                type="text"
                container-class="flex items-center"
                label-class="label-w-46"
                label="Licensee ID"
                disabled
              />
              <base-input
                :model-value="contractDetails.licensor"
                type="text"
                container-class="flex items-center"
                label-class="label-w-46"
                label="Licensor"
                disabled
              />
              <base-input
                :model-value="contractDetails.contractNumber"
                type="text"
                container-class="flex items-center"
                label-class="label-w-46"
                label="Contract Number"
                disabled
              />
              <base-input
                :model-value="contractDetails.contractStartDate"
                type="text"
                container-class="flex items-center"
                label-class="label-w-46"
                label="Start Date"
                disabled
              />
              <base-input
                :model-value="contractDetails.contractEndDate"
                type="text"
                container-class="flex items-center"
                label-class="label-w-46"
                label="End Date"
                disabled
              />
              <base-input
                :model-value="contractDetails.contractType"
                type="text"
                container-class="flex items-center"
                label-class="label-w-46"
                label="Contract Type"
                disabled
              />
              <base-input
                :model-value="contractDetails.contractStatus"
                type="text"
                container-class="flex items-center"
                label-class="label-w-46"
                label="Status"
                disabled
              />
            </div>
          </div>
        </template>
      </collapse-card>

    </div>


    <!-- confirm submit modal -->
    <base-modal
      v-model="showSyncConfirmModal"
      modal-title="Confirm Sync Contract"
    >
      <template #modalBody>
        <div class="px-2 py-3">
          <div class="font-sm text-center mb-2">
            Are you sure you want to Sync this contract manually?
          </div>
          <div class="text-center">
            <base-button
              class="mt-2 mb-2 mr-4"
              variant="btn-primary"
              text="Yes"
              @click="syncContract()"
            />
            <base-button
              class="mt-2 mb-2"
              variant="btn-link"
              text="No"
              @click="handleSyncContractCancel()"
            />
          </div>
        </div>
      </template>
    </base-modal>
  </div>
</template>

<script>
import { computed, defineAsyncComponent, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import useToastNotifications from '@/hooks/toastNotifications.js';
import { formatDate } from '@/helpers/util';
import { ERROR, SUCCESS, WARNING, CONTRACT_SYNC_FAILED, CONTRACT_SYNC_SUCCESS, FETCH_CONTRACT_DETAILS_FAILED, REFRESH_VIEWS_FAILED, REFRESH_VIEWS_SUCCESS, STATUS_SYNC_FAILED, STATUS_SYNC_SUCCESS } from '@/constants/alerts';

export default {
    name: 'ContractSync',

    components: {
        CollapseCard: defineAsyncComponent(() => import('@/components/CollapseCard.vue')),
        BaseInput: defineAsyncComponent(() => import('@/components/generic-components/BaseInput.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        BaseModal: defineAsyncComponent(() => import('@/components/generic-components/BaseModal.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue')),
    },

    setup () {
        const store = useStore();
        const { notificationsStack } = useToastNotifications();
        const contractSyncData = reactive({
            contractNumber: ''
        });
        const isSynctBtnActive = computed(() => contractSyncData.contractNumber.length > 0);

        const handleSync = () => {
            setSyncConfirmModalVisibility(true);
        };
        const isViewRefreshing = ref(false);
        const handleRefreshViews = async () => {
            if (isViewRefreshing.value) {
                return;
            }
            try {
                isViewRefreshing.value = true;

                let resp = await store.dispatch('baLicensee/drmRefreshViews');

                let tmp = { type: SUCCESS, message: REFRESH_VIEWS_SUCCESS }
                if (resp.data && resp.data.data) tmp = resp.data.data
                notificationsStack.value.push(tmp);

                contractSyncData.contractNumber = ''
                contractDetailsData.value = []
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: REFRESH_VIEWS_FAILED
                });
            } finally {
                isViewRefreshing.value = false;
            }
        };

        const isContractStatusSynced = ref(false);
        const handleSyncFormStatus = async () => {
            if (isContractStatusSynced.value) {
                return;
            }
            try {
                isContractStatusSynced.value = true;
                await store.dispatch('baLicensee/drmContractSync', {
                    bodyPayload: {
                        action: 'status',
                        contractNumber: contractSyncData.contractNumber
                    }
                });
                notificationsStack.value.push({
                    type: SUCCESS,
                    message: STATUS_SYNC_SUCCESS
                });
                contractSyncData.contractNumber = '';
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: SUCCESS,
                    message: STATUS_SYNC_FAILED
                });
            } finally {
                isContractStatusSynced.value = false;
            }
        };

        const handleReset = async () => {
            contractSyncData.contractNumber = ''
            contractDetailsData.value = []
        };

        const contractDetailsData = ref([]);

        // computed(() => {
        //     if (fetchedContractDetails.value) {
        //         return {
        //             ...fetchedContractDetails.value,
        //             contractStartDate: formatDate(fetchedContractDetails.value.contractStartDate),
        //             contractEndDate: formatDate(fetchedContractDetails.value.contractEndDate)
        //         };
        //     } else {
        //         return {};
        //     }
        // });

        const isContractDetailsLoading = ref(false);
        const fetchedContractDetails = computed(() => store.getters['baLicensee/getLicenseeContractDetailsList']);
        const fetchContractDetails = async () => {
            if (isContractDetailsLoading.value) {
                return;
            }
            try {
                isContractDetailsLoading.value = true;
                await store.dispatch('baLicensee/fetchDrmLicenseeContractDetails', {
                    id: contractSyncData.contractNumber
                });
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: FETCH_CONTRACT_DETAILS_FAILED
                });
            } finally {
                isContractDetailsLoading.value = false;
            }
        };

        // sync contract confirm modal logic
        const showSyncConfirmModal = ref(false);
        const setSyncConfirmModalVisibility = (visibility) => {
            showSyncConfirmModal.value = visibility;
        };
        const isContractSyncInProgress = ref(false);
        const syncContract = async () => {
            if (isContractSyncInProgress.value) {
                return;
            }
            try {
                isContractSyncInProgress.value = true;
                setSyncConfirmModalVisibility(false);

                let resp = await store.dispatch('baLicensee/drmContractSync', {
                    bodyPayload: {
                        action: 'sync-contract',
                        contractNumber: contractSyncData.contractNumber
                    }
                })

                if (resp.errors) resp.errors.map(e => {
                  notificationsStack.value.push({
                      type: WARNING,
                      message: e
                  })
                })

                if (resp.data && resp.data.length) {

                  contractDetailsData.value = resp.data.map(c => {
                    return {
                      ...c,
                      contractStartDate: formatDate(c.contractStartDate),
                      contractEndDate: formatDate(c.contractEndDate)
                    }
                  })

                  notificationsStack.value.push({
                      type: SUCCESS,
                      message: `Contracts Synced successfully: ${resp.data.map(e=>e.contractNumber).join(', ')}`
                  })
                }

            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: CONTRACT_SYNC_FAILED
                });
            } finally {
                isContractSyncInProgress.value = false;
            }
        };
        const handleSyncContractCancel = () => {
            setSyncConfirmModalVisibility(false);
        };

        return {
            contractSyncData,
            isSynctBtnActive,
            handleSync,
            handleRefreshViews,
            handleSyncFormStatus,
            handleReset,
            // contract details
            contractDetailsData,
            // sync confirm modal
            showSyncConfirmModal,
            syncContract,
            handleSyncContractCancel,
            isContractSyncInProgress,
            isViewRefreshing
        };
    }
};
</script>
